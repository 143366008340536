import React from "react"
import { Flex, Button, Box } from "rebass"
import PhoneInput from "./PhoneInput"
import Popover from "react-popover"
import { useMutation } from "urql"
import gql from "graphql-tag"

const addLeadMutation = gql`
  mutation AddLead($phone: String!) {
    addLead(input: { phone: $phone }) {
      success
    }
  }
`

export default function PhoneForm(props) {
  const [submitted, setSubmitted] = React.useState(false)
  const [error, setError] = React.useState(false)
  const ref = React.useRef()

  const [, addLead] = useMutation(addLeadMutation)

  const onSubmit = React.useCallback(() => {
    setError(false)
    setSubmitted(false)
    console.log(ref.current, ref.current && ref.current.value)
    const add = async () => {
      const value = ref.current && ref.current.value
      if (value) {
        const numValue = value.replace(/\D/g, "")
        console.log(numValue)
        if (/\d{10}/.test(numValue)) {
          const res = await addLead({ phone: numValue })
          if (res.data && res.data.addLead && res.data.addLead.success) {
            return setSubmitted(true)
          } else {
            return setError("Oops, we got an error, please try again later!")
          }
        }
      }
      setError("Please input your phone number")
    }
    add()
  }, [addLead])

  return (
    <Flex {...props}>
      <PhoneInput flex={1} ref={ref} />
      <Box m="2" />
      <Popover
        isOpen={submitted || error}
        body={!submitted ? error : "Thank you, we will contact you ASAP!"}
      >
        <Button
          variant="primary"
          onClick={onSubmit}
          onBlur={() => setSubmitted(false) || setError(false)}
        >
          GET EARLY ACCESS
        </Button>
      </Popover>
    </Flex>
  )
}
