/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Box, Flex, Heading, Button } from "rebass"
import Text from "../components/text"
import Animation from "../components/animation"
import useWindowSize from "../hooks/useWindowSize"

import friends from "../animations/Friends.json"
import pinkBubble from "../animations/Bubble_Pink.json"
import purpleBubble from "../animations/Bubble_Purple.json"
import blueBubble from "../animations/Bubble_Blue.json"
import bottomWaves from "../animations/Bottom_Waves.json"
import map from "../animations/Map.json"
import reward from "../animations/Reward_Points.json"

import Waves from "../images/waves.svg"
import Cubes from "../images/cubes.svg"
import Apple from "../icons/apple.svg"
import Android from "../icons/android.svg"
import ReactTyped from "react-typed"
import PhoneForm from "../components/PhoneForm"
import useIsClient from "../hooks/useIsClient"

const reviews = [
  {
    name: "Aaron",
    title: "Trainee",
    picture: "person",
    text:
      "“Got beta access to this app and Dose was able to bring a personal trainer to the gym in my building!”",
  },
  {
    name: "Danielle",
    title: "Trainee",
    picture: "person2",
    text:
      "“Started training with some of the Dose trainers 3 months ago... What a journey it's been!”",
  },
  {
    name: "Ivan",
    picture: "person3",
    title: "Trainee",
    text: "“Started my day with a Dose session, feeling like a rockstar.”",
  },
  {
    name: "Hailey",
    picture: "person4",
    title: "Trainee",
    text:
      "“Had a session in Central Park this morning, never thought I would get such a great workout outside.”",
  },
]

const IndexPage = () => {
  const isClient = useIsClient()
  const { width } = useWindowSize()

  const data = useStaticQuery(graphql`
    query {
      mainScreen: file(relativePath: { eq: "screen-main.png" }) {
        childImageSharp {
          fluid(maxWidth: 380) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      twoScreens: file(relativePath: { eq: "two-screens.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      card: file(relativePath: { eq: "card.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      user: file(relativePath: { eq: "user.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      nav: file(relativePath: { eq: "nav.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      person: file(relativePath: { eq: "person.png" }) {
        childImageSharp {
          fixed(width: 104, height: 104) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      person2: file(relativePath: { eq: "person2.png" }) {
        childImageSharp {
          fixed(width: 104, height: 104) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      person3: file(relativePath: { eq: "person3.png" }) {
        childImageSharp {
          fixed(width: 104, height: 104) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      person4: file(relativePath: { eq: "person4.png" }) {
        childImageSharp {
          fixed(width: 104, height: 104) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const reviewers = React.useMemo(
    () =>
      reviews.map((r, i) => (
        <Box flex={1} key={i} py="4" px={[3, 4]}>
          <Flex
            flexDirection="column"
            alignItems="center"
            p="3"
            px="4"
            sx={{
              backgroundColor: ["white", "transparent"],
              boxShadow: ["0px 5px 30px rgba(0, 0, 0, 0.2)", "none"],
              borderRadius: 20,
            }}
            height={[360, "auto"]}
          >
            <Box variant="avatar" mb="4">
              <Img fixed={data[r.picture].childImageSharp.fixed} />
            </Box>
            <Text fontSize={[3, 4]} textAlign="center">
              {r.name}
            </Text>
            <Text color="darkGray" textAlign="center">
              {r.title}
            </Text>
            <Text variant="quote" my="3" textAlign="center" fontSize={[1, 2]}>
              {r.text}
            </Text>
          </Flex>
        </Box>
      )),
    [data]
  )

  return (
    <>
      <Layout>
        <SEO title="Home" />
        <Flex
          as="section"
          variant="section"
          pt="0"
          flexDirection={["column", "row", "row"]}
        >
          <Box
            width={[1, 0.6]}
            alignSelf="center"
            p="2"
            sx={{ position: "relative" }}
          >
            <Box
              sx={{
                position: "absolute",
                width: "60%",
                zIndex: -1,
                left: ["50%", "-50%"],
                // height: "100%",
                top: ["-60%", "20%"],
                // bottom: ["-70%", "-80%"],
              }}
            >
              <Animation src={blueBubble} />
            </Box>
            <Heading my="3" textAlign={["center", "left"]}>
              On Demand and Scheduled Personal Training Sessions
            </Heading>
            <Text my="3" fontSize={[1, 3]} textAlign={["center", "left"]}>
              Dose was created to help turn your fitness goals into an active
              lifestyle. Our platform takes the headache out of coordinating
              training sessions and finding venues.
            </Text>
            <PhoneForm
              my="3"
              maxWidth={540}
              flexDirection={["column", "row", "row"]}
            />
            <Flex>
              <Text>Coming soon on</Text>
              <Box as={Android} size={18} mx="2" />
              <Box as={Apple} size={18} />
            </Flex>
          </Box>
          <Box width={[1, 0.4]} p="2" sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                width: "220%",
                zIndex: -1,
                left: ["-20%", "-10vw"],
                bottom: ["-70%", "-45vw"],
              }}
            >
              <Animation src={pinkBubble} />
            </Box>
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                zIndex: -1,
                left: ["-40%", "-40%"],
                // height: "100%",
                bottom: ["35%"],
                // bottom: ["-70%", "-80%"],
              }}
            >
              <Animation src={purpleBubble} />
            </Box>

            <Box maxWidth={380} mx="auto">
              <Img fluid={data.mainScreen.childImageSharp.fluid} />
            </Box>
          </Box>
        </Flex>

        <Flex
          as="section"
          // py="3"
          variant="section"
          flexDirection={["column", "row-reverse", "row-reverse"]}
        >
          <Box
            key={isClient ? "client" : "server"}
            sx={{
              position: "absolute",
              top: ["20%", "15%"],
              left: Math.max((width - 1440) * 0.7, 0),
              transform: `scaleX(${Math.max(1, width / 1440)})`,
              // marginLeft: [0, -3],
              zIndex: -2,
            }}
            width="100vw"
            overflowX="hidden"
          >
            <Waves />
          </Box>
          <Box width={[1, 0.4]} alignSelf="center" p="2">
            <Heading variant="heading2" my="3" textAlign={["center", "left"]}>
              Explore the Dose Effect
            </Heading>
            <Text my="3" textAlign={["center", "left"]}>
              Use the app to schedule a training session in advance or on
              demand. You choose the time and place.
            </Text>
          </Box>
          <Box width={[1, 0.6]} p="2">
            <Box maxWidth={600} mx="auto" sx={{ position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  top: ["-55%", "-70%"],
                  left: "-16%",
                }}
                width={[1, 1]}
              >
                <Box
                  width={1}
                  height="120%"
                  sx={{ top: "-20%", left: "-5%", position: "relative" }}
                >
                  <Animation
                    src={reward}
                    options={{
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                  />
                </Box>
              </Box>
              <Img fluid={data.user.childImageSharp.fluid} />
            </Box>
          </Box>
        </Flex>

        <Flex
          as="section"
          // py="3"
          variant="section"
          flexDirection={["column", "row", "row"]}
        >
          <Box width={[1, 0.45]} alignSelf="center" p="2">
            <Heading variant="heading2" my="3" textAlign={["center", "left"]}>
              At Your{" "}
              <Heading
                as="span"
                variant="heading2"
                color="primary"
                display="inline"
              >
                <ReactTyped
                  strings={[
                    "Building Gym",
                    "Park",
                    "Office Gym",
                    "Grandma's House",
                    "Mom's House",
                    "Basement",
                    "Membership Gym",
                  ]}
                  typeSpeed={50}
                  backSpeed={20}
                  loop
                />
              </Heading>
            </Heading>
            <Text my="3" textAlign={["center", "left"]}>
              Don't have a place to workout? Dose will find somewhere for you to
              train within the radius of your selection.
            </Text>
          </Box>
          <Box width={[1, 0.55]} p="2">
            <Box maxWidth={600} mx="auto" sx={{ position: "relative" }}>
              <Img fluid={data.nav.childImageSharp.fluid} />

              <Box
                sx={{
                  position: "absolute",
                  height: [60, 120],
                  top: [-10, -40],
                  left: "30%",
                  boxShadow: "avatar",
                  borderRadius: 10,
                  backgroundColor: "background",
                }}
                width={[40, 100]}
                // width={0.4}
              >
                <Box
                  width={1}
                  height="120%"
                  sx={{ top: "-20%", left: "-5%", position: "relative" }}
                >
                  <Animation
                    src={map}
                    options={{
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Flex>
        <Flex
          flexDirection={["column", "row-reverse", "row-reverse"]}
          mb={[-6, -6]}
          mt={[-3, -4]}
        >
          <Box width={[1, 0.45]}>
            <Box width={1} maxWidth={[300, 400]} mr="auto" ml={["auto", 0]}>
              <Animation width="100%" src={friends} />
            </Box>
          </Box>
        </Flex>

        <Flex
          as="section"
          // py="3"
          variant="section"
          flexDirection={["column", "row-reverse", "row-reverse"]}
        >
          <Box
            key={isClient ? "client" : "server"}
            sx={{
              position: "absolute",
              zIndex: -2,
              width: "100vw",
              transform: `scaleX(${
                -1 * Math.max(1, width / 1440)
              }) translateY(-30%)`,
              right: Math.max((width - 1440) * 0.7, 0),
            }}
            width="100vw"
            overflowX="hidden"
          >
            <Waves />
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "100vw",
              height: "50vw",
              left: 0,
            }}
          >
            {/* <Box
              as={Waves}
              sx={{
                position: "absolute",
                left: ["-100%", 0],
                top: ["-90%", "-25%"],
                zIndex: -1,
                transform: "scaleX(-1)",
              }}
            /> */}
            <Box
              as={Cubes}
              sx={{
                position: "absolute",
                left: ["0", "-20%"],
                top: ["150%", "15%"],
                width: "100vw",
                zIndex: -1,
              }}
            />
          </Box>
          <Box width={[0, 0.05]} />
          <Box width={[1, 0.4]} alignSelf="center" p="2">
            <Heading variant="heading2" my="3" textAlign={["center", "left"]}>
              On Your Own or With a Friend
            </Heading>
            <Text my="3" textAlign={["center", "left"]}>
              With Session Matching you can be paired with a fellow Dose member
              or invite a friend. Lower Rates, More Fun!
            </Text>
          </Box>
          <Box flex={1} />
          <Box width={[1, 0.5]} p="2">
            <Box width={1} maxWidth={400} mx="auto">
              <Img fluid={data.card.childImageSharp.fluid} />
            </Box>
          </Box>
        </Flex>
        <Box as="section" mx="auto" py={[6, 7]}>
          <Text variant="heading" textAlign="center">
            Try Dose Today!
          </Text>
          <Text fontSize={[1, 3]} textAlign="center" my="3">
            Have a personal training session booked in minutes.
          </Text>
          <Flex>
            <Button
              as="a"
              href="#start"
              variant="primary"
              // display="block"
              mx="auto"
              px="4"
            >
              GET EARLY ACCESS
            </Button>
          </Flex>
        </Box>

        <Box as="section" mx={-3} sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              width: ["40%", "15vw"],
              zIndex: -1,
              left: ["70%", "70vw"],
              // height: "100%",
              top: ["60%", "5vw"],
              // bottom: ["-70%", "-80%"],
            }}
          >
            <Animation src={blueBubble} />
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: ["80%", "30vw"],
              zIndex: -1,
              left: ["-30%", "-10vw"],
              // height: "100%",
              top: ["-50%", "-25vw"],
              // bottom: ["-70%", "-80%"],
            }}
          >
            <Animation src={pinkBubble} />
          </Box>
          <Box display={["block", "none"]}>
            <Carousel
              infiniteLoop
              autoPlay
              stopOnHover
              swipeable
              emulateTouch
              showThumbs={false}
              showStatus={false}
              showArrows={false}
              showIndicators={false}
              centerMode
              centerSlidePercentage={80}
            >
              {reviewers}
            </Carousel>
          </Box>
          <Box as={Flex} flexDirection="row" display={["none", "flex"]}>
            {reviewers}
          </Box>
        </Box>

        <Flex
          as="section"
          id="start"
          variant="section"
          // py="3"
          flexDirection={["column", "row", "row"]}
          sx={{ position: "relative" }}
        >
          <Box width={[1, 0.6]} alignSelf="center" p="2" mb={[5, 0]}>
            <Heading my="3" mb={[4, "5"]} textAlign={["center", "left"]}>
              Start Your Journey!
            </Heading>

            <PhoneForm
              my="3"
              maxWidth={540}
              flexDirection={["column", "row", "row"]}
            />
            <Flex>
              <Text>Coming soon on</Text>
              <Box as={Android} size={18} mx="2" />
              <Box as={Apple} size={18} />
            </Flex>
          </Box>
          <Box width={[1, 0.4]} p="2">
            <Box maxWidth={600} mx="auto">
              <Img fluid={data.twoScreens.childImageSharp.fluid} />
            </Box>
          </Box>
        </Flex>
      </Layout>

      <Box
        sx={{
          position: "absolute",
          width: "100vw",
          // height: "100%",
          zIndex: -1,
          left: 0,
          bottom: "0",
          // height: 400,
          overflow: "hidden",
          "& svg": {
            transform: "translate3d(0, 60%, 0px) !important",
          },
          // transform: "translateY(100%)",
        }}
        opacity={0.3}
      >
        <Animation
          src={bottomWaves}
          options={{
            rendererSettings: {
              preserveAspectRatio: "xMidYMax meet",
            },
          }}
        />
      </Box>
    </>
  )
}

export default IndexPage
