import React from "react"
import Lottie from "react-lottie"

export default function Animation({ src, options = {}, ...props }) {
  const opts = {
    loop: true,
    autoplay: true,
    animationData: src,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    ...options,
  }
  return <Lottie options={opts} {...props} />
}
