import { useState, useEffect } from "react"
import useIsClient from "./useIsClient"

export default function useWindowSize() {
  const isClient = useIsClient()

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (isClient) {
      function handleResize() {
        setWindowSize(getSize())
      }

      handleResize()
      window.addEventListener("resize", handleResize)
      return () => window.removeEventListener("resize", handleResize)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClient])

  // return React.useMemo(
  //   () => ({
  //     ...windowSize,
  //     breakpoint,
  //   }),
  //   [windowSize, breakpoint]
  // )
  return windowSize
}
