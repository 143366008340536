import React from "react"
import InputMask from "react-input-mask"
import { Input } from "@rebass/forms"

export default React.forwardRef(function PhoneInput(props, ref) {
  return (
    <InputMask maskChar="_" mask="(999) 999-9999" alwaysShowMask={false}>
      {prps => (
        <Input {...prps} {...props} placeholder="(000) 000-0000" ref={ref} />
      )}
    </InputMask>
  )
})
